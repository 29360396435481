<template>
    <div class="changeLease">
        <!--        导航-->
        <div>
            <van-nav-bar title="变更合同" left-arrow @click-left="leftReturn" />
        </div>
        <!--        租约详情-->
        <div class="leaseDetail">
            <img v-if="changeOfList.path" class="leaseDetailImg" :src="changeOfList.path" />
            <div v-else class="leaseDetailNullImg"></div>
            <div class="leaseDetailText">

                <p class="addressInfo">{{changeOfList.houseAddress}}</p>

                <div class="detailInfo">
                    <p class="pTwo">{{changeOfList.contractBeginTime}}至{{changeOfList.contractEndTime}}</p>
                    <p class="pTwo">{{changeOfList.rentMoney}}/月  (押一)</p>
                    <p class="pTwo">{{changeOfList.payTypeName}}</p>
                </div>
            </div>
        </div>
        <div v-if="capacityElectricity" class="electricity">是智能电表</div>
        <!--        变更类型-->
        <div class="changeType">
            <div :class="!leaseType? 'grayDot' : 'redDot'"></div>
            <div class="pOne">变更类型</div>
            <div class="ver">|</div>
            <!--            <div>-->
            <!--                <select v-model="selected" @change="changeSelect($event)">-->
            <!--                    <option :value="i" v-for="(item,i) in slist" :key="i">{{item}}</option>-->
            <!--                </select>-->
            <!--            </div>-->

            <span class="typesShow" @click="showLeaseChange">{{leaseType?leaseType:'请选择变更类型'}}</span>
            <img src="../../../assets/images/triangle.png" :class="!isShowTitle?'downImage':'upImage'">
        </div>
        <van-popup v-model="isShowTitle" position="bottom">
            <van-picker show-toolbar :columns="slist"
                        @cancel="isShowTitle = false" @confirm="selectType" />
        </van-popup>
        <!--        离房原因-->
        <!--<div class="changeType">
            <div :class="date=='' ? 'grayDot' : 'redDot'"></div>
            <div class="pOne">预计离房时间</div>
            <div class="ver">|</div>

            <span class="vanCell" @click="show = true"  :disabled="ifRenew"> {{date}}</span>
            <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="show = true"/>

        </div>

        <van-popup v-model="show" position="bottom" class="selectTime">
            <div class="date-dropDownList" >
                <van-datetime-picker type="date" title="选择年月日" :min-date="minDate" item-height="35px" @cancel="cancel" @confirm="onConfirm"/>
            </div>
        </van-popup>-->
        <!--        解除日期-->
        <div class="changeType">
            <div :class="date=='' ? 'grayDot' : 'redDot'"></div>
            <div class="pOne">解除日期</div>
            <div class="ver">|</div>
<!--            <input class="vanCell" v-model="date" placeholder="预计离开时间" @click="show = true"/>-->
            <span class="vanCell" @click="relieveShow = true"  :disabled="ifRenew"> {{relieveDate}}</span>
            <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="relieveShow = true"/>
<!--            <van-calendar v-model="show"  @confirm="onConfirm"/>-->
        </div>

        <van-popup v-model="relieveShow" position="bottom" class="selectTime">
            <div class="date-dropDownList" >
                <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate" item-height="35px" @cancel="relieveCancel" @confirm="relieveOnConfirm"/>
            </div>
        </van-popup>
        <!--        实际离房原因-->
        <div class="changeType">
            <div :class="realDate=='' ? 'grayDot' : 'redDot'"></div>
            <div class="pOne">实际离房时间</div>
            <div class="ver">|</div>
<!--            <input class="vanCell" v-model="date" placeholder="预计离开时间" @click="show = true"/>-->
            <span class="vanCell" @click="realShow = true"  :disabled="ifRenew"> {{realDate}}</span>
            <img class="changeTypeImg" src="../../../assets/images/calendar.png" @click="realShow = true"/>
<!--            <van-calendar v-model="show"  @confirm="onConfirm"/>-->
        </div>

        <van-popup v-model="realShow" position="bottom" class="selectTime">
            <div class="date-dropDownList" >
                <van-datetime-picker type="date" title="选择年月日" :min-date="minDate" item-height="35px" @cancel="realCancel" @confirm="realOnConfirm"/>
            </div>
        </van-popup> 
        <!--解除类型-->
        <div class="part-inputpart" v-if="changeType!=6&&type==1">   
          <div
            id="paymentMethodPanel"
            class="part-inputpart-row"
            @click="selectRelieveType"
          >
            <span
              :class="
                contractInfo.relieveType ? 'content-have' : 'content-none'
              "
            ></span>
            <span class="part-inputpart-row-header">解除类型</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.relieveType
                  ? 'part-inputpart-row-normaltext'
                  : ''
              "
              >{{ contractInfo.relieveType.dictionaryTitle }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                relieveTypeShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
        </div> 
          <van-popup v-model="relieveTypeShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="relieveTypeList"
              @cancel="relieveTypeShow = false"
              @confirm="selectRelieveTypeOption"
            />
          </van-popup>                 
        <div class="part-inputpart" v-if="type==1">
          <div class="part-inputpart-row">
            <div class="redDot"></div>
            <div>
              <span
                class="part-inputpart-isSupport part-inputpart-row-header"
                :class="
                  contractInfo.normalSurrender
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="isSupportingService"
                >正常退租</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  !contractInfo.normalSurrender
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="noSupportingService"
                >非正常退租</span
              >
            </div>
          </div>
        </div>
        <div class="part-inputpart" v-if="type==1">
          <div class="part-inputpart-row">
            <div class="redDot"></div>
            <div>
              <span
                class="part-inputpart-isSupport part-inputpart-row-header"
                :class="
                  contractInfo.conscientiousBreak
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="updateConscientious"
                >有责违约</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  !contractInfo.conscientiousBreak
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="updateNoConscientious"
                >无责违约</span
              >
            </div>
          </div>
        </div>       
        <div class="part-inputpart" v-if="type==1">   
          <div
            id="paymentMethodPanel"
            class="part-inputpart-row"
            @click="selectDefaultParty"
          >
            <span
              :class="
                contractInfo.defaultParty ? 'content-have' : 'content-none'
              "
            ></span>
            <span class="part-inputpart-row-header">违约方</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.defaultParty
                  ? 'part-inputpart-row-normaltext'
                  : ''
              "
              >{{ contractInfo.defaultParty.label }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                defaultPartyShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
        </div> 
          <van-popup v-model="defaultPartyShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="label"
              :columns="wrongSideList"
              @cancel="defaultPartyShow = false"
              @confirm="selectDefaultPartyOption"
            />
          </van-popup>  
        <!-- 解除原因 -->
        <div class="part-inputpart" v-if="type==1">   
          <div
            id="paymentMethodPanel"
            class="part-inputpart-row"
            @click="selectRelieveReason"
          >
            <span
              :class="
                contractInfo.relieveReason ? 'content-have' : 'content-none'
              "
            ></span>
            <span class="part-inputpart-row-header">解除原因</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                contractInfo.relieveReason
                  ? 'part-inputpart-row-normaltext'
                  : ''
              "
              >{{ contractInfo.relieveReason.dictionaryTitle }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isRelieveReasonShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
        </div> 
          <van-popup v-model="isRelieveReasonShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="terminateReasonList"
              @cancel="isRelieveReasonShow = false"
              @confirm="selectRelieveReasonOption"
            />
          </van-popup>  

        <div class="part-inputpart" v-if="type==1">
            <div class="part-inputpart-row">
              <div
                class="content-have"
              ></div>
              <span class="part-inputpart-row-header">维修赔偿</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="digit"
                  v-model="contractInfo.maintainCompensation"
                  placeholder="维修赔偿"
                />
              </div>
              <span
                class="part-inputpart-row-right part-inputpart-row-short-graytext"
                >元</span
              >
            </div>
            <div class="part-inputpart-row" v-if="type==1">
              <div
                class="content-have"
              ></div>
              <span class="part-inputpart-row-header">预留水电费</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field
                  class="nopadding"
                  :formatter="formatter"
                  type="digit"
                  v-model="contractInfo.reservedUtilities"
                  placeholder="预留水电费"
                />
              </div>
              <span
                class="part-inputpart-row-right part-inputpart-row-short-graytext"
                >元</span
              >
            </div>            
        </div>                                          
        <!--        变更原因-->
        <div class="changeCause">
            <div class="textareaTitle">
                <div :class="text=='' ? 'grayDot' : 'redDot'"></div>
                <div class="pOne">变更原因</div>
            </div>
            <textarea class="textareaInput" v-model="text"></textarea>
        </div>
        <div class="part-inputpart" v-if="type==1">
          <div class="part-inputpart-row">
            <div class="redDot"></div>
            <div>
              <span
                class="part-inputpart-isSupport part-inputpart-row-header"
                :class="
                  contractInfo.checkoutClean
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="updateCheckout"
                >生成退租保洁</span
              >
              <span
                class="content-divide content-divide-position part-inputpart-row-short-graytext"
                >|</span
              >
              <span
                class="part-inputpart-woman part-inputpart-row-header"
                :class="
                  !contractInfo.checkoutClean
                    ? 'part-inputpart-row-redtext'
                    : 'part-inputpart-row-graytext'
                "
                @click="updateNoCheckout"
                >不生成退租保洁</span
              >
            </div>
          </div>
        </div>         
        <!--        补充说明-->
        <!--<div class="changeCause">
            <div class="textareaTitle">
                <div :class="contractInfo.supplement=='' ? 'grayDot' : 'redDot'"></div>
                <div class="pOne">补充说明</div>
            </div>
            <textarea class="textareaInput" v-model="contractInfo.supplement"></textarea>
        </div>-->        

        <div v-if="changeType==3">
            <div class="changeRenter">转租信息</div>
            <div class="changeType">
                <div :class="!newRenterPhone? 'grayDot' : 'redDot'"></div>
                <div class="pOne">租客电话</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="goToChoosePhone">{{newRenterPhone?newRenterPhone:'请输入电话号码'}}</span>
                <img :src="defaultImage" class="imageTo">
            </div>
            <div>
<!--                <van-popup v-model="isMobileShow" position="bottom">-->
<!--                    &lt;!&ndash;搜索栏&ndash;&gt;-->
<!--                    <div class="searchDiv">-->
<!--                        <van-search  placeholder="请输入" v-model="phoneSearch" size="large" @input="quaryPhoneList()"/>-->
<!--                    </div>-->
<!--                    <van-picker show-toolbar value-key="mobile" :columns="phoneArr"-->
<!--                                @cancel="isMobileShow = false"-->
<!--                                @confirm="selectPhone" />-->
<!--                </van-popup>-->
            </div>
            <div class="changeType">
                <div class="pOne-1">姓名</div>
                <div class="ver">|</div>
                <span class="typesShow">{{newRenterName}}</span>
            </div>
            <div class="changeType" v-if="!newRenterCeTypeFlag">
                <div class="pOne-1">证件号</div>
                <div class="ver">|</div>
                <span class="typesShow">{{newRenterCeType}}</span>
            </div>
            <div class="changeType" v-if="newRenterCeTypeFlag">
                <div class="pOne-1">证件号</div>
                <div class="ver">|</div>
                <div class="nopadding">
                    <van-field class="nopadding typesShow" @blur="animateOwnerIdCard"  v-model="newRenterCeType" type="text" placeholder="填写证件号码"
                               />
                </div>
<!--                <input class="roomHouse"  placeholder="请输入" v-model="newRenterCeType" size="large"/>-->
<!--                <input v-model="newRenterCeType" placeholder="证件号">-->
            </div>
            <div class="changeType">
                <div class="pOne-1">转租原因</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="isReasonShow=true">{{changeLeaseReason?changeLeaseReason:'请选择原因'}}</span>
                <img src="../../../assets/images/triangle.png" :class="!isReasonShow?'downImage':'upImage'">
            </div>
            <div>
                <van-popup v-model="isReasonShow" position="bottom">
                    <van-picker show-toolbar value-key="dictionaryTitle" :columns="reasonArr"
                                @cancel="isReasonShow = false"
                                @confirm="selectReason" />
                </van-popup>
            </div>
            <div class="changeType">
                <div :class="!changeLeaseTime ? 'grayDot' : 'redDot'"></div>
                <div class="pOne">房屋转出时间</div>
                <div class="ver">|</div>
<!--                <input class="vanCell-1" v-model="changeLeaseTime" placeholder="预计转出时间" @click="showLeaseTime = true"/>-->
                <span class="vanCell-1" > {{changeLeaseTime}}</span>
                <img class="changeTypeImg" src="../../../assets/images/calendar.png" />
<!--                <van-calendar v-model="showLeaseTime"  @confirm="onConfirmLeaseTime"/>-->
            </div>


            <van-popup v-model="showLeaseTime" position="bottom" class="selectTime">
                <div class="date-dropDownList" >
                    <van-datetime-picker type="date" title="选择年月日" :min-date="minDate" item-height="35px" @cancel="cancel" @confirm="onConfirmLeaseTime"/>
                </div>
            </van-popup>
        </div>


        <div v-if="changeType==4">
            <div class="changeRenter">房屋信息</div>
            <div class="changeType">
                <div :class="!changeRoomAddress? 'grayDot' : 'redDot'"></div>
                <div class="pOne">房屋地址</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="goToChooseRoomList">{{changeRoomAddress?changeRoomAddress:'请选择地址'}}</span>
                <img :src="defaultImage" class="imageTo">
            </div>
            <div>
<!--                <van-popup v-model="isRoomShow" position="bottom">-->
<!--                    &lt;!&ndash;搜索栏&ndash;&gt;-->
<!--                    <div class="searchDiv">-->
<!--                        <van-search  placeholder="请输入" v-model="roomSearch" size="large" @input="quaryRoomList()"/>-->
<!--                    </div>-->
<!--                    <van-picker show-toolbar value-key="roomDetailedAddress" :columns="roomList"-->
<!--                                @cancel="isRoomShow = false"-->
<!--                                @confirm="selectRoom" />-->
<!--                </van-popup>-->
            </div>
        </div>

        <div v-if="changeType==6">
            <div class="changeRenter">新签信息</div>
            <div class="changeType">
                <div :class="!newRenterPhone? 'grayDot' : 'redDot'"></div>
                <div class="pOne">租客电话</div>
                <div class="ver">|</div>
                <span class="typesShow" @click="goToChoosePhone">{{newRenterPhone?newRenterPhone:'请输入电话号码'}}</span>
                <img :src="defaultImage" class="imageTo">
            </div>
            <div>
<!--                <van-popup v-model="isMobileShow" position="bottom">-->
<!--                    &lt;!&ndash;搜索栏&ndash;&gt;-->
<!--                    <div class="searchDiv">-->
<!--                        <van-search  placeholder="请输入" v-model="phoneSearch" size="large" @input="quaryPhoneList()"/>-->
<!--                    </div>-->
<!--                    <van-picker show-toolbar value-key="mobile" :columns="phoneArr"-->
<!--                                @cancel="isMobileShow = false"-->
<!--                                @confirm="selectPhone" />-->
<!--                </van-popup>-->
            </div>
            <div class="changeType">
                <div class="pOne-1">姓名</div>
                <div class="ver">|</div>
                <span class="typesShow">{{newRenterName}}</span>
            </div>
            <div class="changeType" v-if="!newRenterCeTypeFlag">
                <div class="pOne-1">证件号</div>
                <div class="ver">|</div>
                <span class="typesShow">{{newRenterCeType}}</span>
            </div>
            <div class="changeType" v-if="newRenterCeTypeFlag">
                <div class="pOne-1">证件号</div>
                <div class="ver">|</div>
                <div class="nopadding">
                    <van-field class="nopadding typesShow" @blur="animateOwnerIdCard"  v-model="newRenterCeType" type="text" placeholder="填写证件号码"
                               />
                </div>
<!--                <input class="roomHouse"  placeholder="请输入" v-model="newRenterCeType" size="large"/>-->
<!--                <input v-model="newRenterCeType" placeholder="证件号">-->
            </div>
            <div>
                <van-popup v-model="isReasonShow" position="bottom">
                    <van-picker show-toolbar value-key="dictionaryTitle" :columns="reasonArr"
                                @cancel="isReasonShow = false"
                                @confirm="selectReason" />
                </van-popup>
            </div>
            <div class="changeType">
                <div :class="!changeLeaseTime ? 'grayDot' : 'redDot'"></div>
                <div class="pOne">房屋转出时间</div>
                <div class="ver">|</div>
<!--                <input class="vanCell-1" v-model="changeLeaseTime" placeholder="预计转出时间" @click="showLeaseTime = true"/>-->
                <span class="vanCell-1" @click="showLeaseTime = true"> {{changeLeaseTime}}</span>
                <img class="changeTypeImg" src="../../../assets/images/calendar.png"/>
<!--                <van-calendar v-model="showLeaseTime"  @confirm="onConfirmLeaseTime"/>-->
            </div>


            <van-popup v-model="showLeaseTime" position="bottom" class="selectTime">
                <div class="date-dropDownList" >
                    <van-datetime-picker type="date" title="选择年月日" :min-date="minDate" item-height="35px" @cancel="cancel" @confirm="onConfirmLeaseTime"/>
                </div>
            </van-popup>
        </div>
        <button @click="submit" :class="!canSave() ? 'btnGray' : 'btnOrange'"
                :disabled="!canSave()">提交</button>
    </div>
</template>

<script>
    import {NavBar, Calendar, cell, Picker, Popup,Field, search, DatetimePicker} from 'vant';
    import {changeOfLeaseInit,queryBaseData,userLeaseChangeSave,remoteMobileSearch,findUserInfoByMobile,getRenterRoomHouseList,getStaffFuntionModelList,intelligentHydropower,getQueryBaseData} from "../../../getData/getData";
    import {
      globaluserId,
      responseUtil,
      getStaffId,
      checkAuthList,
      openInWebview,
      checkAndroid, checkIOS
    } from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus";
    import config from "../../contract/config";
    import util from "../../contract/util";
    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
      window.himi.closeWindow();
    }
    //调用android关闭页面方法*******end*********
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Calendar .name]:Calendar,
            [cell .name]:cell,
            [Picker.name]: Picker,
            [Field.name]:Field,
            [Popup.name]: Popup,
            [search.name]:search,
            [DatetimePicker.name]: DatetimePicker,
        },
        data() {
            return {
                isShowTitle:false,
                leaseType:'',
                changeOfList:[],
                fdName: ["CONTRACTHISTORYHISTORYTYPEMAP"],
                dbName: ["subletReason","contractTerminationReason"],
                typeArr:[],
                defaultImage:require('../../../assets/images/youjiantou.png'),
                slist: [],
                text : '',
                date : '',
                show : false,
                selected : -1,
                changeType:'',
                type:'',// 1租客 2业主
                id:'',//间（套）房源id（间roomHouse_id/套setHouse_id）
                contractId:'',
                mode:'',//mode 0我的 1工作台
                ownerOrRenterContractId:'',
                fromType: '',  // 入口标识，房源详情转租1 房源详情预到期签约2，  合同列表租约变更不传
                mobile:'',
                houseAddress:'',
                estateRoom_id:'',
                minDate: new Date(), //  离房时间最小时间
                contractCode:'',//合同编号
                potentialResource_id:'',
                //转租信息  换房信息
                //选择电话
                newRenterPhone:'',
                isMobileShow:false,
                phoneSearch:'',
                phoneArr:[],
                newRenterName:'',
                newRenterCeType:'',
                newRenterCeTypeFlag: false,
                //转租原因
                isReasonShow:false,
                changeLeaseReason:'',
                reasonArr:[],
                //时间
                showLeaseTime:false,
                changeLeaseTime:'',
                //房屋地址
                changeRoomAddress:'',
                isRoomShow:false,
                roomSearch:'',
                roomList:[],
                changeRoomId:'',//换房的新房源id
                authOwnerButtonsList:[],//业主合同按钮权限列表
                authRenterButtonsList:[],//租客合同按钮权限列表
                contractEndTime:'',       //合同截止时间
                ifRenew:false,             //是否为续签
                terminateType:'',
                currentDate:'',
                realDate:'',     //实际离房时间
                relieveDate:'',  //解除时间                     
                realShow:false,
                relieveShow:false,
                isRelieveReasonShow:false,
                contractInfo:{
                  normalSurrender:true,               //正常退租
                  conscientiousBreak:false,           //有责违约
                  checkoutClean:true,                 //生成退租保洁
                  defaultParty:{label:'',value:''},    //违约方
                  relieveReason:{dictionaryTitle:'',id:''},    //解除原因
                  maintainCompensation:'',              //维修赔偿
                  reservedUtilities:'',                 //预留水电费
                  supplement:'',                         //补充说明
                  relieveType:{}                          //解除类型
                },
                defaultPartyShow:false,                //显示违约方列表
                wrongSideList: [                       //违约方列表
                  {
                    value: '2',
                    label: '租客'
                  },
                  {
                    value: '0',
                    label: '平台'
                  }
                ],
                terminateReasonList:[],               //解除原因集合     
                editable:false,                       //是否可编辑       
                capacityElectricity:false,            //是否是智能电表  
                //relieveTypeList:[],                   //解除类型集合 
                relieveTypeShow:false, 
            relieveTypeList: [
                {
                    value: config.TERMINATE_TYPE_NORMAL,
                    label: '正常退房'
                },
                {
                    value: config.TERMINATE_TYPE_BREAK,
                    label: '违约'
                },
                {
                    value: config.TERMINATE_TYPE_TRANSFER,
                    label: '转租'
                },
                {
                    value: config.TERMINATE_TYPE_CHANGE,
                    label: '换房'
                },
                {
                    value: config.TERMINATE_TYPE_GOON,
                    label: '续签'
                },
                {
                    value: config.TERMINATE_TYPE_NO_REPONSE,
                    label: '无责解除'
                }
            ],                                 

            }
        },
        mounted() {
            console.log(this.$route.query.fromType)
          if(this.$route.query.fromType == undefined){
            this.type = this.$route.query.type
            this.id = this.$route.query.id
            // console.log(this.id+"id")
            this.contractId = this.$route.query.contractId
            this.mode = this.$route.query.mode
            this.ownerOrRenterContractId = this.$route.query.ownerOrRenterContractId
            this.mobile = this.$route.query.mobile
            this.houseAddress = this.$route.query.houseAddress
            this.estateRoom_id = this.$route.query.estateRoom_id
            this.contractCode = this.$route.query.contractCode
            this.potentialResource_id = this.$route.query.potentialResource_id
          }else if(this.$route.query.fromType != undefined && this.$route.query.fromType == '1'){

            //房源详情转租
            this.fromType = this.$route.query.fromType;
            this.type = '1'  //租客
            this.mode = '1'
            this.id = this.$route.query.room_id  // 间房源id
            this.contractId = this.$route.query.contractId  // 合同id

            //this.selectType("转租")
          }else if(this.$route.query.fromType != undefined && this.$route.query.fromType == '2'){
            //房源详情转租
            this.fromType = this.$route.query.fromType;
            this.type = '1'  //租客
            this.mode = '1'
            this.id = this.$route.query.room_id  // 间房源id
            this.contractId = this.$route.query.contractId  // 合同id
            //this.selectType("续签")
          }

            this.initData()
            this.getQueryBaseData()
            this.quaryPhoneList()
            this.quaryRoomList()
            this.getOwnerStaffFuntionModelList()
            this.getRenterStaffFuntionModelList()
            this.getIntelligentHydropower()
          if(this.contractInfo.normalSurrender){
             this.editable=true
          }
          this.getBaseData()
        },

        activated() {
            eventBus.$on('selectRenterMobileList', function(data){
                if(data.mobile){
                    this.newRenterPhone = data.mobile
                    this.findUserInfoByMobile(this.newRenterPhone)
                }
            }.bind(this));
            eventBus.$on('selectContractRoomList', function(data){
                if(data.roomInfo){
                    this.changeRoomAddress = data.roomInfo.roomDetailedAddress
                    this.changeRoomId = data.roomInfo.id
                }
            }.bind(this));
        },

        //如果前往的页面不是推荐人，则摧毁缓存
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'selectRenterMobileList' && to.name != 'selectContractRoomList')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },

        methods :{
        //基础数据
        getBaseData: function () {
            var me = this;
            getQueryBaseData({
                dbName: ['contractTerminationType','contractTerminationReason','subletReason'],
            }).then(function (data) {
                responseUtil.dealResponse(me, data, () => {
                    var lists = data.data.data;
                    me.relieveTypeList = lists['contractTerminationType'];
                    console.log(me.relieveTypeList)
                    if(me.$route.query.fromType == '1'){
                       me.selectType('转租')  
                    }else if(me.$route.query.fromType == '2'){
                       me.selectType('续签')
                    }
                })
            });
        },            
            getIntelligentHydropower(){
                var that = this
                let data = {}
                data.id = that.$route.query.setHouse_id
                console.log(data)
                intelligentHydropower(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if(response.data.data.is=='1'){
                           that.capacityElectricity=true
                        }else{
                           that.capacityElectricity=false
                        }
                    })
                })
            },
            selectRelieveType(){
                this.relieveTypeShow=!this.relieveTypeShow
            },
            //解除类型
            selectRelieveTypeOption(item){
                console.log(item)
                this.relieveTypeShow=false
                this.contractInfo.relieveType=item
            },
            //解除原因
            selectRelieveReason(){
                if(this.editable){
                    return
                }                
               this.isRelieveReasonShow=!this.isRelieveReasonShow 
            },
            selectRelieveReasonOption(item){
                console.log(item)
               this.isRelieveReasonShow=false
               this.contractInfo.relieveReason=item
            },
            //违约方
            selectDefaultPartyOption(item){
                console.log(item)
                this.defaultPartyShow=false
                this.contractInfo.defaultParty=item
            },
            selectDefaultParty(){
                if(this.editable){
                    return
                }
               this.defaultPartyShow=!this.defaultPartyShow
            },
            //退租保洁
            updateCheckout(){
               this.contractInfo.checkoutClean=true
            },
            updateNoCheckout(){
               this.contractInfo.checkoutClean=false
            },
            //有责违约
            updateConscientious(){
               if(this.contractInfo.normalSurrender){
                  return
               }
               this.contractInfo.conscientiousBreak=true
            },
            //无责违约
            updateNoConscientious(){
               this.contractInfo.conscientiousBreak=false
            },
            //正常退租            
            isSupportingService(){
                this.editable=true
               this.contractInfo.normalSurrender=true
               this.contractInfo.conscientiousBreak=false
               this.contractInfo.defaultParty={}
               this.contractInfo.relieveReason={}
               this.contractInfo.relieveType={dictionaryTitle:'正常退房',id:'108'}  
            },
            //非正常退租
            noSupportingService(){
               this.editable=false
               this.contractInfo.normalSurrender=false
               this.contractInfo.defaultParty={label:'租客',value:'2'}
               this.contractInfo.relieveReason=this.terminateReasonList[0]
               this.contractInfo.relieveType={dictionaryTitle:'违约退房',id:'109'}  
            },
            //输入框的公共方法，过滤掉空格
            formatter(value) {
              // 过滤空格
              return value.replace(/\s*/g, '')
            },            
            //获取业主合同权限方法
            getOwnerStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'ownerContractList_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authOwnerButtonsList = response.data.data.data
                    })
                })
            },

            //获取租客合同权限方法
            getRenterStaffFuntionModelList () {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'renterContract_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authRenterButtonsList = response.data.data.data
                    })
                })
            },

            checkAuthList,

            showLeaseChange(){
                console.log(this.fromType)
                if(this.fromType == 1){
                  return
                }
                let that = this
                //根据权限动态改变数组
                let list = []
                console.log(that.authRenterButtonsList)
                if(that.type==1){
                    if(checkAuthList(that.authRenterButtonsList,'renewal')){
                        list.push('续签')
                    }
                    if(checkAuthList(that.authRenterButtonsList,'terminate')){
                        list.push('退房')
                    }
                    if(checkAuthList(that.authRenterButtonsList,'toSublet')){
                        list.push('转租')
                    }
                    if(checkAuthList(that.authRenterButtonsList,'exchangeHouses')){
                        list.push('换房')
                    }
                    if(checkAuthList(that.authRenterButtonsList,'newSignature')){
                        list.push('新签')
                    }
                } else {
                    if(checkAuthList(that.authOwnerButtonsList,'renewal')){
                        list.push('续签')
                    }
                    if(checkAuthList(that.authOwnerButtonsList,'terminate')){
                        list.push('退房')
                    }
                }
                that.slist = list
                console.log(that.slist)
                that.isShowTitle = true
            },
            getTolerantRelieveType(item){
                if(!item){
                     return
                }
                for(let i in this.relieveTypeList){
                     if(item==this.relieveTypeList[i].dictionaryTitle){
                       return this.relieveTypeList[i]
                   }
                }
            },
            selectType(item){
                console.log(item)
              debugger
                this.leaseType = item
                if(item=='续签'){
                    this.changeType = 1
                    this.relieveDate=this.contractEndTime
                    this.realDate=this.contractEndTime
                    this.ifRenew=true
                    this.terminateType=112
                    this.editable=true
                    this.contractInfo.normalSurrender=true
                    this.contractInfo.defaultParty={}
                    this.contractInfo.relieveReason={}
                    this.contractInfo.relieveType=this.getTolerantRelieveType(item)                      
                } else if(item=='退房'){
                    this.changeType = 2             
                    this.relieveDate=this.formatDate(new Date())
                    this.realDate=this.formatDate(new Date())
                    this.ifRenew=false
                    this.editable=false
                    this.contractInfo.normalSurrender=false
                    this.contractInfo.defaultParty={label:'租客',value:'2'}
                    this.contractInfo.relieveReason=this.terminateReasonList[0]  
                    this.contractInfo.relieveType={dictionaryTitle:'违约退房',id:'109'}                   
                } else if(item=='转租'){
                    this.changeType = 3
                    this.relieveDate=this.formatDate(new Date())
                    this.realDate=this.formatDate(new Date())
                    this.ifRenew=false
                    this.editable=false
                    this.contractInfo.normalSurrender=false
                    this.contractInfo.defaultParty={label:'租客',value:'2'}
                    this.contractInfo.relieveReason=this.terminateReasonList[0] 
                    this.contractInfo.relieveType=this.getTolerantRelieveType(item)  
                    this.changeLeaseTime = this.getNewDataDays(this.date,1)                      
                } else if(item=='换房'){
                    this.changeType = 4
                    this.relieveDate=this.formatDate(new Date())
                    this.realDate=this.formatDate(new Date())
                    this.ifRenew=false
                    this.editable=false
                    this.contractInfo.normalSurrender=false
                    this.contractInfo.defaultParty={label:'租客',value:'2'}
                    this.contractInfo.relieveReason=this.terminateReasonList[0]
                    this.contractInfo.relieveType=this.getTolerantRelieveType(item)                         
                }else if(item=='新签'){
                    this.changeType = 6
                    this.relieveDate=this.formatDate(new Date())
                    this.realDate=this.formatDate(new Date())
                    this.ifRenew=false
                    this.editable=true
                    this.contractInfo.normalSurrender=true
                    this.contractInfo.defaultParty={}
                    this.contractInfo.relieveReason={}   
                    this.changeLeaseTime = this.getNewDataDays(this.date,1)                  
                }
                this.isShowTitle = false
            },

            // leftReturn(){
            //     this.$router.go(-1)
            // },
            // 变更租约初始化
            initData(){
                var that = this
                let initData = {}
                // 接收参数
                initData.type = that.type// 1租客 2业主
                initData.id =  that.id //间（套）房源id（间roomHouse_id/套setHouse_id）
                initData.ownerOrRenterContractId = that.ownerOrRenterContractId
              debugger
                initData.user_id=globaluserId()
                //initData.user_id=44
                changeOfLeaseInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.changeOfList = response.data.data
                        console.log(that.changeOfList)
                        //默认离房时间为合同的结束时间
                        //that.date = response.data.data.contractEndTime
                        console.log(that.date)
                        that.date=that.formatDate(new Date())
                        that.realDate=that.formatDate(new Date())
                        that.relieveDate=that.changeOfList.contractEndTime
                        that.currentDate=new Date(that.changeOfList.contractEndTime)
                        that.contractEndTime=response.data.data.contractEndTime
                      if(that.fromType == '1' || that.fromType == '2'){
                        debugger
                        that.ownerOrRenterContractId = response.data.data.renterContractId
                        that.houseAddress = response.data.data.houseAddress
                      }
                        that.changeLeaseTime = that.getNewDataDays(that.date,1)
                    })
                })
            },
            // 字典表接口
            getQueryBaseData(){
                var that = this
                let queryBD = {}
                // 接收参数
                queryBD.fdName=that.fdName
                queryBD.dbName=that.dbName
                //console.log(queryBD)
                queryBaseData(queryBD).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        that.typeArr = response.data.data.CONTRACTHISTORYHISTORYTYPEMAP
                        that.reasonArr = response.data.data.subletReason
                        that.terminateReasonList=response.data.data.contractTerminationReason
                        let type = that.type// 1租客 2业主
                        if(type=='2')
                            that.typeArr = that.typeArr.slice(0,2)
                        for (let index = 0; index < that.typeArr.length; index++) {
                            that.slist.push(that.typeArr[index].dictionaryTitle)
                        }
                    })
                })
            },
            formatDate(date){
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var days = date.getDate();
                if (days < 10) days = "0" + days;
                return year + "-" + month + "-" + days;
            },
            onConfirm(value){
                let that = this
                that.show=false
                var date = new Date(value)
                that.date = that.formatDate(date)
                this.changeLeaseTime = this.getNewDataDays(this.date,1)
            },
            cancel(){
                let that = this
                that.show=false
                that.showLeaseTime=false
            },
            realOnConfirm(value){
                let that = this
                that.realShow=false
                var date = new Date(value)
                that.realDate = that.formatDate(date)
                this.changeLeaseTime = this.getNewDataDays(this.date,1)
            },
            realCancel(){
                let that = this
                that.realShow=false
                that.showLeaseTime=false                
            },
            relieveOnConfirm(value){
                this.relieveShow=false
                var date=new Date(value)
                this.relieveDate=this.formatDate(date)
                //this.changeLeaseTime = this.getNewDataDays(date,1)
                this.changeLeaseTime=util.dateFormat(util.dateAddDay(date,1))
            },
            relieveCancel(){
                this.relieveShow=false
            },
            // checkInDateConfirm(value){
            //     this.toTime = value
            //     var date = new Date(value)
            //     this.checkInDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
            //     this.isCheckInDateShow = false
            // },
            changeSelect(event){
                this.selected=event.target.value
            },

            //进入手机号选择页面
            goToChoosePhone(){
                this.$router.push('selectRenterMobileList')
            },

            //进入房间选择页面
            goToChooseRoomList(){
                this.$router.push({
                    name:'selectContractRoomList',
                    query:{
                        flagType:4,
                    }
                })
            },

            //根据输入的号码模糊查询手机号列表
            quaryPhoneList(){
                let that = this
                let queryData = {}
                queryData.searchInfo = that.phoneSearch
                remoteMobileSearch(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.phoneArr = response.data.data.data
                    })
                })
            },
            //手机号下拉选择
            selectPhone(item){
                this.newRenterPhone = item.mobile
                this.findUserInfoByMobile(this.newRenterPhone)
                this.isMobileShow = false
            },
            findUserInfoByMobile(mobile){
                let that = this
                let initData = {}
                initData.userMobile = mobile
                findUserInfoByMobile(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let result = response.data.data.data
                        if (result && result.userName) {
                            that.newRenterName = result.userName
                            that.newRenterCeType =  result.certificateCode || ''
                            if(result.certificateCode == undefined || result.certificateCode == ''){
                                that.newRenterCeTypeFlag = true
                            }else{
                                that.newRenterCeTypeFlag = false
                            }
                        }
                    })
                })
            },
            //选择转租原因
            selectReason(item){
                this.changeLeaseReason = item.dictionaryTitle
                this.isReasonShow = false
            },
            //监听租客身份证信息
            animateOwnerIdCard() {
                var re = config.CERTIFICATE_REG
                let str = this.newRenterCeType
                if(str != '' && str != undefined){
                    if (!re.test(str)) {
                        responseUtil.alertMsg(this,'身份证格式不正确')
                        //this.ownerInfo.cardNumber = "";
                    }
                }
            },
            //转租时间
            onConfirmLeaseTime(value){
                this.showLeaseTime = false
                this.changeLeaseTime = this.formatDate(value)
            },
            getNewDataDays(dateTemp, days) {
                var arr = dateTemp.split("-");
                var nDate = new Date(arr[1] + "/" + arr[2] + "/" + arr[0]); //转换为MM-DD-YYYY格式
                var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
                var rDate = new Date(millSeconds);
                var year = rDate.getFullYear();
                var month = rDate.getMonth() + 1;
                if (month < 10) month = "0" + month;
                var date = rDate.getDate();
                if (date < 10) date = "0" + date;
                return year + "-" + month + "-" + date;
            },
            //房源列表模糊查询
            quaryRoomList(){//getRoomHouseList
                let that = this
                let initData = {}
                initData.staff_id = getStaffId()
                initData.searchInfo = that.roomSearch
                getRenterRoomHouseList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let result = response.data.data.data
                        that.roomList = result
                    })
                })
            },
            //具体房间选择查询
            selectRoom(item){
                this.changeRoomAddress = item.roomDetailedAddress
                this.changeRoomId = item.id
                this.isRoomShow = false
            },

            //检验可不可以提交的方法
            canSave(){
                let that = this
                if(that.type==1){
                    //租客的租约变更
                    if(that.leaseType&&that.date&&that.text&&(that.changeType==1 || that.changeType==2 ||
                        (that.changeType==3&&that.newRenterPhone&&that.changeLeaseTime) ||
                        (that.changeType==6&&that.newRenterPhone&&that.changeLeaseTime) ||
                        (that.changeType==4&&that.changeRoomAddress))){
                        return true
                    }
                } else {
                    //业主的租约变更
                    if(that.leaseType&&that.date&&that.text){
                        return true
                    }
                }
                return false
            },

          leftReturn() {
            if(openInWebview()){
              //APP传值*******begin*********
              if(checkAndroid()){
                backToAndroid();
              }else if(checkIOS()){
                window.webkit.messageHandlers.closeWindow.postMessage({});
              }
              //APP传值*******end*********
            }else{
              this.$router.go(-1)
            }
          },


            //提交申请
            submit(){
                let that = this;
                let sbm = {}
                sbm.user_id= globaluserId()
                sbm.roomOrHouse_id=this.id
                sbm.type=this.type // 2业主  1租户
                //sbm.changeType=parseInt(that.selected)+1 //变更类型 1续租 2退租
                sbm.changeType=that.changeType
                sbm.changeDate= that.relieveDate //日期
                sbm.actualDepartureTime=that.realDate
                sbm.changeReason= that.text //变更原因
                sbm.cId = this.contractId//加一个上一页面传入的合同id
                
                sbm.terminateInfoParam=[]
                var json={}
                json.normalType=that.contractInfo.normalSurrender==true?1:0
                json.hasResponsibility=that.contractInfo.conscientiousBreak==true?1:0
                json.payRepair=that.contractInfo.maintainCompensation
                json.reservedWater=that.contractInfo.reservedUtilities
                json.wrongSide=that.contractInfo.defaultParty.value
                json.terminateReason=that.contractInfo.relieveReason.id
                json.remarks=that.contractInfo.supplement
                json.leaveTime=that.relieveDate
                json.terminateType=that.contractInfo.relieveType.id?that.contractInfo.relieveType.id:''
                sbm.terminateInfoParam.push(json)
                /*sbm.terminateInfoParam.normalType=that.contractInfo.normalSurrender==true?1:0
                sbm.terminateInfoParam.hasResponsibility=that.contractInfo.conscientiousBreak==true?1:0
                sbm.terminateInfoParam.payRepair=that.contractInfo.maintainCompensation
                sbm.terminateInfoParam.reservedWater=that.contractInfo.reservedUtilities
                sbm.terminateInfoParam.wrongSide=that.contractInfo.defaultParty.value
                sbm.terminateInfoParam.terminateReason=that.contractInfo.relieveReason.id
                sbm.terminateInfoParam.remarks=that.contractInfo.supplement
                sbm.terminateInfoParam.leaveTime=that.relieveDate*/
                sbm.refund=that.contractInfo.checkoutClean==true?1:0
                
                console.log(sbm)
                // 接收参数
                debugger
                //如果是退租那么调用保存接口,其它情况进入新增页面之后再把填写的数据保存到历史记录里
                if(that.mode==0 || (that.mode==1&&sbm.changeType==2)){
                    userLeaseChangeSave(sbm).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            responseUtil.alertMsg(that,'操作成功')
                            // let contracthistoryId = response.data.data.contractHistoryId
                            //重定向到列表页面 //mode 0我的 1工作台
                            if(that.mode=='0'){
                                that.$router.push("myContract")
                            }else if(that.mode=='1'&&sbm.type=='2'&&sbm.changeType=='2') {
                                that.$router.push({
                                    name : 'contractList',
                                    query : {
                                        currentLabel: '1',
                                        type:'2'
                                    }
                                })
                            } else if(that.mode=='1'&&sbm.type=='1'&&sbm.changeType=='2'){
                                that.$router.push({
                                    name : 'contractList',
                                    query : {
                                        currentLabel: '1',
                                        type:'1'
                                    }
                                })
                            }
                        })

                    })
                } else {
                    if(that.mode=='1'&&sbm.type=='2'&&sbm.changeType==1){
                        //业主合同续签 新增业主合同
                        let changeInfoParam = {
                            ownercontract_id:that.ownerOrRenterContractId,
                            normalType:'1',
                            hasResponsibility:'0',
                            addStaff_id:getStaffId(),
                            changeType:that.changeType,
                            leaveTime:that.date,
                            remarks:that.text
                        }
                        that.$router.push({
                            name: 'newOwnerContract',
                            params:{
                                flagType:'2',
                                ownerContractId:that.ownerOrRenterContractId,
                                mobile:that.mobile,
                                houseAddress:that.houseAddress,
                                estateRoom_id:that.estateRoom_id,
                                contractCode:that.contractCode,
                                prId:that.potentialResource_id,
                                changeInfoParam:changeInfoParam
                            }
                        })
                    } else if (that.mode=='1'&&sbm.type=='1'&&sbm.changeType!=2){
                        //除了退租以外都要跳转到新增租客合同页面
                        //新增分三种情况 续签 转租 换房
                        let changeInfoParam = {
                            rentercontract_id:that.ownerOrRenterContractId,
                            normalType:'1',
                            hasResponsibility:'0',
                            addStaff_id:getStaffId(),
                            changeType:that.changeType,
                            leaveTime:that.date,
                            remarks:that.text,
                            actualDepartureTime:that.realDate
                        }
                        console.log(that.realDate)
                        //1.续签  (原房间信息不变,租客信息不变)
                        if(sbm.changeType==1){
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'2',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    changeInfoParam:changeInfoParam,
                                    roomId:that.id,     //赵
                                    alertType:sbm.changeType,  //赵
                                    terminateType:that.terminateType,
                                    terminateInfoParam:sbm.terminateInfoParam,
                                    refund:sbm.refund
                                }
                            })
                        }
                        //2.转租  (房间信息不变,租客信息变化，需要往新增页面传入新的租客信息)
                        if(sbm.changeType==3){
                          debugger
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'3',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    roomId:that.id,
                                    mobile:that.mobile,
                                    newRenterMobile:that.newRenterPhone,
                                    newRoomId:that.changeRoomId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    houseAddress:that.houseAddress,
                                    changeInfoParam:changeInfoParam,
                                    roomId:that.id,     //赵
                                    alertType:sbm.changeType,  //赵
                                    terminateInfoParam:sbm.terminateInfoParam,
                                    refund:sbm.refund                                    
                                }
                            })
                        }
                        //3.换房  (房间信息变化,需要重新选择房间,租客信息不变)
                        if(sbm.changeType==4){
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'4',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    mobile:that.mobile,
                                    newRoomId:that.changeRoomId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    houseAddress:that.changeRoomAddress,
                                    changeInfoParam:changeInfoParam,
                                    roomId:that.id,     //赵
                                    alertType:sbm.changeType,  //赵
                                    terminateInfoParam:sbm.terminateInfoParam,
                                    refund:sbm.refund                                    
                                }
                            })
                        }
                        if(sbm.changeType==6){
                            that.$router.push({
                                name: 'NewContract',
                                query:{
                                    flagType:'6',//2代表续签 3代表转租 4代表换房
                                    contractId:that.ownerOrRenterContractId,
                                    roomId:that.id,
                                    mobile:that.mobile,
                                    newRenterMobile:that.newRenterPhone,
                                    newRoomId:that.changeRoomId,
                                    newStartDate:that.getNewDataDays(that.date,1),
                                    houseAddress:that.houseAddress,
                                    changeInfoParam:changeInfoParam,
                                    roomId:that.id,     //赵
                                    alertType:sbm.changeType,  //赵  
                                    terminateInfoParam:sbm.terminateInfoParam,
                                    refund:sbm.refund                                                                       
                                }
                            })
                        }                        
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped >
    .changeLease{
        overflow: hidden;
        width: 100%;
    }
    .typesShow{
        font-size: 15px;
        width: 200px;
        color: #999999;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .nopadding{
        padding: 0;
        flex: auto;
    }

    .roomHouse{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .downImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
    .upImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotateX(180deg);
    }

    *{
        margin: 0;
        padding: 0;
    }
    .pOne{
        font-size: 15px;
        font-weight: bold;
        margin-left: 10px;
    }
    .pOne-1{
        font-size: 15px;
        font-weight: bold;
        margin-left: 31px;
    }
    .pTwo{
        font-size:12px ;
        color: #999999;
    }
    //租约详情
    .leaseDetail{
        width: 92%;
        margin: 15px auto 0px;
        background-color: #FFFFFF;
        border-radius: 8px;
        height: 108px;
        display: flex;
        .leaseDetailImg{
            width: 100px;
            height: 75px;
            margin: 15px 10px 18px;
            border-radius: 8px;
        }
        .leaseDetailNullImg{
            width: 100px;
            height: 75px;
            margin: 15px 10px 18px;
            background-color: #D8D8D8;
            border-radius: 8px;
        }
        .leaseDetailText{
            margin-top: 15px;
            .addressInfo{
                font-size: 15px;
                font-weight: bold;
                height: 21px;
                line-height: 21px;
                width: 205px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .detailInfo{
                height: 51px;
                line-height: 17px;
                margin-top: 5px;
            }
        }
    }
    .grayDot{
        border: 3px solid #999999;
        border-radius: 50%;
        margin-left:15px;
    }
    .redDot{
        border: 3px solid #FF5D3B;
        border-radius: 50%;
        margin-left:15px;
    }
    .ver{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 15px;
        color:#999999 ;
    }
    .changeType {
        width: 92%;
        height: 50px;
        line-height: 40px;
        display: flex;
        border-radius: 8px;
        background-color: #FFFFFF;
        margin: 15px auto 0;
        align-items: center;
        .changeTypeImg{
            width: 25px;
            height: 25px;
        }
        .vanCell{
            /*height: 40px;*/
            /*line-height: 40px;*/
            width: 46%;
            border: none;
            font-size: 15px;
            color: #FF5D3B;
        }
        .vanCell-1{
            /*height: 40px;*/
            /*line-height: 40px;*/
            width: 47%;
            border: none;
            font-size: 15px;
            color: #FF5D3B;
        }
        select{
            width: 215px;
            height: 40px;
            border: none;
            background-color: #FFFFFF;
            margin-bottom: 5px;
        }
    }
    .changeCause{
        width: 92%;
        height: 185px;
        margin: 27px auto;
        background-color: #FFFFFF;
        border-radius: 8px;
        .textareaTitle{
            display: flex;
            height: 45px;
            line-height: 45px;
            align-items: center;
        }
        .textareaInput{
            display: block;
            width: 84%;
            margin: -3px auto 20px;
            height: 90px;
            border-radius: 8px;
            background-color: #f7f7f7;
            border: none;
            resize: none;
            padding: 15px;
            font-size: 14px;
        }
    }
    .btnGray,.btnOrange{
        display: block;
        width: 92%;
        height: 45px;
        color: #FFFFFF;
        border-radius: 8px;
        font-size: 16px;
        margin: 44px auto 35px;
        border: none;
    }
    .btnGray{
        background-color:#B8B8B8  ;
    }
    .btnOrange{
        background-image: linear-gradient(to right,#FFC274,#FF5D3B);
    }
    .changeRenter{
        font-size: 15px;
        font-weight: bold;
        margin-top: 15px;
        margin-left: 15px;
    }
    .imageTo{
        width: 9px;
        height: 16px;
    }
.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  margin-left:15px;
  width:92%;
}    
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;
}

.part-inputpart-row-line {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 65px;
  background-color: #ffffff;
  margin-top: 15px;
  flex-wrap: wrap;
}

.part-inputpart-row-short {
  width: 48%;
  float: left;
}

.part-inputpart-row-short-right {
  float: right;
}

.part-inputpart-row-short-graytext {
  color: #d8d8d8;
}

.part-inputpart-row-header {
  font-weight: 900;
}

.part-inputpart-row-sex {
  margin-top: 26px;
}

.part-inputpart-row-startPeriod {
  width: 60px;
  line-height: 21px;
  float: left;
}

.part-inputpart-row-endPeriod {
  width: 100px;
  height: 20px;
  line-height: 21px;
  margin-left: 8px;
  float: left;
}    
.part-inputpart-row-graytext {
  width: 100px;
  color: #d8d8d8;
  flex: auto;
}

.part-inputpart-row-normaltext {
  color: black;
  flex: auto;
}

.part-inputpart-row-redtext {
  flex: auto;
  color: #ff5d3b;
}
.part-inputpart-isSupport {
  margin-left: 10px;
  margin-right: 41px;
}
.part-inputpart-woman{
  margin-left: 10px;

}
.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}
.part-inputpart-row-right-upArrow {
  width: 8px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}
.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;

}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 20px;
}
.electricity{
    color:red;
    font-size:12px;
    margin: 10px 30px;
}
</style>
